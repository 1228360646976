/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ProductWishlistButton {
    display: inline-block;
    height: var(--wishlist-heart-size);

    &-Button.Button {
        background-color: unset;
        border: none;
        height: var(--wishlist-heart-size);
        padding: 0;
        
        @include mobile {
            width: auto;
        }

        &:hover,
        &:focus {
            background-color: unset;
            height: var(--wishlist-heart-size);
            padding: 0;
        }

        .HeartIcon {
            stroke: var(--primary-base-color);
        }
    }

    &-Text {
        color: var(--primary-base-color);
        font-weight: 700;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.04rem;
        display: none;
        padding-inline-start: 5px;
    }
}
