/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    width: 100%;
    margin-block-end: 50px;

    @include mobile {
        margin-block-start: 14px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        padding-block: 0;

        @include mobile {
            padding-inline: 0;
        }
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
            grid-template-columns: 1fr;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 24px;
            justify-content: center;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &-Title {
        font-weight: 300;
        font-size: 40px;
        line-height: 40px;
        border-top: 1px solid #E7E6E5 ;

        @include mobile {
            padding-block-start: 30px;
            padding-block-end: 18px;
            padding-inline: 0;
        }

        @include desktop {
            padding-block-start: 30px;
            padding-block-end: 36px;
            padding-inline: 0;
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }
}

.ProductActions {
    &-ProductLinks {
        .ProductLinks {
            .ProductCard {
                &-LinkInnerWrapper {
                    height: auto;
                    min-height: 0;
                }

                &-Content {
                    height: auto;
                }
                
                &-GroupedItems {
                    display: none;
                }
            }
        }
    }
}
