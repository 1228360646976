/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ProductInformation {
    font-size: 14px;
    line-height: 20px;

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding-block: 32px;
        }

        @include mobile {
            margin-block-end: 50px;
        }
    }

    &-ExpandableContentContent {
        line-height: 20px;
        text-align: justify;

        + div {
            margin-block-end: 12px;
        }

        &_isContentExpanded {
            margin-block-end: 16px;
        }
    }

    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Content {
        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-Description {
        word-break: break-word;
        font-weight: 300;
        font-size: 20px;
        line-height: 28px;

        @include description-tags {
            font-weight: 300;
            font-size: 20px;
            line-height: 28px;
        }

        strong, b {
            font-size: 20px;
            line-height: 28px;
        }

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }

        @include default-list;
    }

    &-Technical {
        &Toggle {
            button {
                display: flex;
                align-items: center;
                padding: .5rem 0;

                svg {
                    margin-inline-start: 5px;
                }

                button {
                    color: black;
                }
            }
        }

        &Content {
            ul {
                li {
                    padding-inline-start: 1.5rem;

                    &::before {
                        content: '-';
                        font-size: 2.5rem;
                        line-height: 20px;
                        color: var(--primary-base-color);
                        inset-inline-start: 0;
                    }
                }
            }
        }
    }
}
