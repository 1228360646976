/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ProductPage {
    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }

    @include mobile {
        padding-block-end: 133px;
        margin-inline: 16px;
        margin-block-start: 0px;
    }

    &-Gallery {
        @include mobile {
            padding-inline: 0px;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 30% 65%;
        grid-column-gap: 5%;
        grid-template-rows: min-content min-content;
        padding-block-start: 24px;

        @include desktop {
            grid-column-gap: 5%;
            grid-template-areas: '. actions';
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            grid-template-rows: min-content min-content min-content;
            grid-template-areas: 'actions' 'attributes' 'ratings';
            padding: 0;

            .ProductAttributes-Wrapper {
                grid-area: attributes;
            }

            .ProductActions-Wrapper {
                display: contents;

                .ProductActions {
                    grid-area: actions;
                }

                .ProductReviews {
                    grid-area: ratings;
                }
            }
        }

        @include desktop {
            &_SpecialLayout {
                .ProductAttributes-Wrapper {
                    margin-block-start: 4rem;
                }
            }
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }

        &.Cigar {
            margin-block-start: 44px;

            @include desktop {
                grid-template-columns: 25% 70%;
                grid-column-gap: 5%;

                .ProductActions-Wrapper {
                    .ProductActions + .ProductReviews {
                        margin-block-start: 100px;
                    }
                }
            }
        }

        .ProductActions + .ProductReviews {
            margin-block-start: 100px;
        }

        .ProductGallery + .ProductActions {
            margin-block-end: 100px;
        }
    }

    &-BackButton {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        margin-block-end: 20px;

        * {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        span {
            margin-inline-end: 5px;
        }

        button {
            color: black;
        }
    }
}
