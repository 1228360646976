/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* eslint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ProductAttributes {
    font-size: 14px;
    line-height: 20px;

    &-Wrapper {
        @include desktop {
            padding-block-end: 32px;
        }
    }

    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        &_isContentExpanded {
            @include mobile {
                padding-block: 0;
            }
        }

        @include desktop {
            columns: 2;
            column-gap: 60px;
        }
    }

    &-AttributeBlock {
        break-inside: avoid;
        // overflow: hidden;
        width: 100%;

        @include mobile {
            margin-block-start: 50px;
        }

        &:not(:last-child) {
            .ProductAttributes-Attributes {
                border-block-end: none;
            }
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Group {
        margin: 0;
        padding-block: 32px 16px;
    }

    &-Attributes {
        display: grid;
        grid-template-columns: 45% 55%;
        justify-content: center;
        align-items: flex-start;
        border-block: 1px solid #E7E6E5;
    }

    &-RatingAttributes {
        display: grid;
        grid-template-columns: 1fr 50px;
        justify-content: center;
        align-items: flex-start;
        border-block: 1px solid #E7E6E5;
    }

    &-RatingLabel {
        margin-block-start: 30px;
        font-weight: bold;
        font-size: 18px;
    }

    &-ValueLabel,
    &-AttributeLabel {
        border-block-end: 1px solid #E7E6E5;
        flex: 0 0 50%;
        // overflow: hidden;
        padding-block: 10px;
        text-overflow: ellipsis;
        height: 100%;

        @include mobile {
            line-height: 20px;
        }

        &:last-of-type {
            border: none;
        }
    }

    &-AttributeLabelDate {
        color: #888;
        margin-inline-start: 10px;
        font-weight: normal;
    }

    &-ValueLabel {
        font-weight: 300;
        font-size: 17px;
        color: #1E1E1E;
        letter-spacing: 0.04em;

        img {
            max-height: auto;
            max-width: auto;
            object-fit: cover;
            vertical-align: middle;
        }

        .Image-Content {
            position: inherit;
        }
    }

    &-AttributeLabel {
        font-weight: 600;
        color: #1E1E1E;
        letter-spacing: 0.04em;
        font-size: 14px;
        text-transform: uppercase;
        display: inline-flex;
        position: relative;
        z-index: 2;
    }

    &-Image {
        padding-block-end: 40%;
        mix-blend-mode: multiply;
        margin-inline-end: 120px;
        min-width: 360px;
        background: none;

        @include mobile {
            margin-inline-end: 140px;
            min-width: 420px;
            display: none;
        }
    }

    &-ToolTip {
        width: 15px;
        margin-inline-start: 10px;

        p {
            margin: 0;
        }

        .react-tooltip {
            @include mobile {
                max-width: 225px;
            }
        }
    }
}
