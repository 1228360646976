/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// util class, is applied on <html> on fullscreen image

:root {
    --carousel-scroll-item-height: 38px;
    --carousel-scroll-margin-top: 28px;
}

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: column;
    height: 500px;
    

    &.Cigar {
        height: 300px;
        padding-inline: 150px;
        background-color: var(--color-white);

        @include mobile {
            height: 120px;
            margin-block-end: 100px;
            padding-inline: 0px;
        }
    }

    @include narrow-desktop {
        //min-width: 507px;
    }

    @include mobile {
        height: calc(100vh / 2);
        margin-block-end: 16px;
    }

    &-Additional {
        flex: 0 0 auto;
        min-height: calc(var(--carousel-scroll-item-height) + var(--carousel-scroll-margin-top));
        overflow: hidden;
        overflow-x: auto;
        padding-block-end: 25px;

        @include mobile {
            display: none;
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }

        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;
        height: 100%;

        .Slider-Arrow {
            display: none;
        }
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);

        &_isImageZoomPopupActive {
            cursor: pointer;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }
    }

    &-Scale {
        background-color: var(--color-white);
        color: #BBBAB8;

        .Scale-Wrapper {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            padding-block: 5px;

            .segment {
                border-top: 1px solid #BBBAB8;
                padding-block-start: 15px;

                &:nth-child(even) {
                    border-top-color: #F4F1EC;
                }
            }
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
    }
}

.Cigar_SpecialLayout {
    .ProductGallery {
        height: auto;

        &-Slider {
            .Image, img {
                aspect-ratio: 1;
                height: auto;
            }
        }
    }
}

.ImageZoomPopup {
    .ProductGallery {
        height: 100%;

        &-Scale {
            display: none;
        }
    }
}
