/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
    --product-card-header-height: 23px;
}

.ProductCard {
    // height: 100%;
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;
    background-color: var(--color-white);

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 7px;

        @include mobile {
            margin-block-end: 8px;
        }
    }

    &-Header {
        padding-inline: 20px;
        padding-block-start: 25px;
        padding-block-end: 5px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mobile {
            padding-block-start: 15px;
            padding-block-end: 15px;
        }

        .ProductWishlistButton-Text {
            display: block;
        }

        .ProductLabels {
            min-height: var(--product-card-header-height);
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            > span {
                white-space: nowrap;
            }
        }
    }

    .ProductConfigurableAttributes {
        &-Title {
            display: none;
        }
    }

    &-Attributes {
        padding: 0;
    }

    &-ConfigurationNotice {
        opacity: 0.3;
        font-weight: normal;
        margin-block-start: -10px;
        margin-block-end: 10px;
    }

    &_layout_list {
        // display: block;

        .ProductCard-FigureReview {    
            @include desktop {
                padding-block: 10px;
                padding-inline: 30px;
                width: 80%;
            }
        }

        .ProductCard {
            &-Link {
                &_isShortCigar {
                    display: grid;
                    grid-template-columns: minmax(auto, 300px) auto;
                    grid-template-rows: auto auto auto;

                    .ProductCard-Header {
                        grid-column: 1 / 3;
                        grid-row: 1 / 2;
                    }

                    .ProductCard-Link {
                        grid-column: 1 / 2;
                        grid-row: 2 / 4;
                    }

                    .ProductCard-Content {
                        grid-row: 3 / 4;
                    }

                    .ProductCard-FigureReview {
                        @include desktop {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &-GroupedItems {
        // position: absolute;
        background-color: var(--color-white);
        // inset-block-start: 60px;
        z-index: 9;
        inset-inline-start: 0;
        inset-inline-end: 0;
        // padding: 0 20px;
        // padding-block-end: 16px;
    }

    &-Content {
        background: var(--product-card-background);
        padding-block: 10px 16px;
        display: flex;
        flex-direction: column;
        position: relative;
        // height: 213px;

        .ProductCard-GroupedItems {
            .GroupedProductsItemList {
                .GroupedProductsItem {
                    &:nth-child(n+3) {
                        display: none;
                    }
                }
            }

            .ProductCard-ShowMore {
                font-size: 14px;
                display: flex;
                align-items: center;
                font-weight: 400;
                letter-spacing: 0.01rem;
            }
        }

        &_showAllGroupedItems {
            .ProductCard-GroupedItems {
                // padding-block-end: 16px;

                .GroupedProductsItemList {
                    .GroupedProductsItem {
                        &:nth-child(n+3) {
                            display: flex;
                        }
                    }
                }

                .ProductCard-ShowMore {
                    padding-block-start: 14px;
                }
            }
        }

        @include desktop {
            padding-inline: 20px;
        }

        @include mobile {
            padding-inline: 20px;
        }

        &_layout_list {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            height: unset;

            .GroupedProductsItemList {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;

                .GroupedProductsItem {
                    display: grid;
                    margin-block-end: 0px;
                    border: none;

                    &-Title {
                        .ProductPrice {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;
                    visibility: hidden;

                    &_loaded {
                        visibility: visible;
                    }

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-OutOfStock {
                    p {
                        margin-inline-end: 10px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                    margin-block-end: 16px;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Description {
        display: none;
    }

    &-Name {
        margin: 5px 0;
        padding-block-end: 15px;
        overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;

        @include mobile {
            font-size: 26px;
        }

        .TextPlaceholder {
            margin-block-end: 0;
        }
    }

    &-Brand {
        font-weight: 400;
        margin-block-end: 4px;
        opacity: .5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-size: 12px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-block-end: 100%;

        @include mobile {
            padding-block-end: 100%;
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-LinkInnerWrapper {
        width: 100%;
        height: 100%;

        &:hover {
            @include desktop {
                box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .1),
                    0 6px 16px rgba(0, 0, 0, .1),
                    0 9px 28px 8px rgba(0, 0, 0, .1);
            }

            // .ProductCard-Content_showAllGroupedItems {
            //     .ProductCard-GroupedItems {
            //         @include desktop {
            //             box-shadow: 1px 9px 7px 4px rgba(0,0,0,0.1);
            //             -webkit-box-shadow: 1px 9px 7px 4px rgba(0,0,0,0.1);
            //             -moz-box-shadow: 1px 9px 7px 4px rgba(0,0,0,0.1);
            //         }
            //     }
            // }
        }

        &_loaded:hover {
            @include desktop {
                .ProductCard-VisibleOnHover {
                    background: var(--product-card-background);
                    width: inherit;
                    opacity: 1;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .1),
                        0 6px 16px rgba(0, 0, 0, .1),
                        0 9px 28px 8px rgba(0, 0, 0, .1);
                    z-index: -1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    flex-grow: 1;
                    padding-block-start: 0;
                    padding-block-end: 10px;
                    padding-inline: 15px;
                    position: absolute;
                    inset-block-start: 100%;
                }
            }
        }
    }

    &-FigureReview {
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include desktop {
            padding-block: 10px;
            padding-inline: 15px;
        }
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;

        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Price {
        width: 100%;

        @include desktop {
            font-size: 24px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;
    }

    .ProductPrice-BundleTo {
        @include mobile {
            margin-block-start: 6px;
        }
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            color: initial;
            text-decoration: none;
        }
    }

    &-ProductActions {
        display: flex;
        margin-inline-start: 7px;

        .ProductWishlistButton {
            margin-inline-end: 10px;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-block-start: 5px;

        > * {
            margin-inline-end: 5px;
        }
    }

    &-OutOfStock {
        align-items: center;
        display: flex;
        height: 48px;

        p {
            margin: 0;
        }
    }

    &-VisibleOnHover {
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 10px;
        padding-inline: 15px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    .GroupedProductsItem {
        display: flex;
        // align-items: flex-end;
        justify-content: space-between;
        border-bottom: 1px solid #E7E6E5;
        padding-block-end: 10px;

        &:last-child {
            border-bottom: 0px;
        }

        &-QuantityAddToCart {
            display: flex;
            align-items: flex-end;
            flex-direction: row;

            .AddToCart {
                border-radius: 0px;
                border: none;
                height: 35px;
                width: 35px;
                display: flex;
                align-items: center;
                justify-content: center;

                .CartIcon {
                    width: unset;
                    height: 100%;
                    fill: var(--color-white);
                    padding: 7px;
                    margin: 0px;
                    transform: scale(1);
                }

                &-Label {
                    display: none;
                }
            }
        }

        &-DeliveryIcon {
            display: none;
        }

        &-Select {
            margin: 0px;

            .FieldSelect-Select {
                background-color: #F4F1EC;
                border: none;
                color: var(--secondary-dark-color);
                font-weight: 700;
                font-size: 12px;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
                border-radius: 0px;
                height: 35px;
                min-width: 65px;
                padding: 0px 15px;
            }
            
            .ChevronIcon_style_primary {
                fill: var(--secondary-dark-color);
            }
        }

        &-Title {
            justify-content: flex-end;
            align-items: flex-start;
            width: 100%;

            p {
                font-weight: 400;
                font-size: 12px;
                margin: 0px;
                line-height: 15px;
                
                @include desktop {
                    text-overflow: ellipsis;
                    max-width: none;
                    // min-height: 45px;
                }
            }

            .GroupedProductItem-PricePerUnit {
                display: none;
            }
            
            .ProductPrice {
                @include desktop {
                    min-height: unset;
                }

                &-Price {
                    display: inline;
                    line-height: 15px;
                }

                &-PriceValue {
                    line-height: 15px;
                    font-weight: 600;
                    font-size: 15px;
                }
            }
        }
    }

    &-Scale {
        background-color: var(--color-white);
        color: #BBBAB8;
        font-weight: normal;

        .measure {
            color: #BBBAB8;
        }

        .Scale-Wrapper {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            padding-block: 5px;

            .segment {
                border-top: 1px solid #BBBAB8;
                padding-block-start: 15px;
                color: #BBBAB8;

                &:nth-child(even) {
                    border-top-color: #F4F1EC;
                }
            }
        }
    }
}

.ProductPrice_hasDiscount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
}
