/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductReviews {
    border-top: 1px solid #E7E6E5;
    
    &-Wrapper {
        padding: 0;

        @include desktop {
            padding: unset;
        }
    }

    & &-Button {
        position: static;
        margin-block-start: 30px;
        font-weight: 500;
        font-size: 13px;
        display: inline-flex;
        line-height: 42px;
        align-items: center;
        cursor: pointer;

        * {
            display: flex;
            align-items: center;
        }

        @include mobile {
            position: relative;
            width: 100%;
            margin-block: 18px;
            line-height: 20px;
        }

        span {
            color: var(--primary-base-color);
            margin-inline-start: 5px;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }

        .ProductReviewRating {
            align-items: center;
            margin-block-start: 0px;
        }
    }
}

.ProductAttributes-AttributeBlock + .ProductReviews {
    margin-block-start: 0px;
}
