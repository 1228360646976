/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.GroupedProductsItem {
    display: grid;
    align-items: start;
    padding-inline-start: 0;
    margin-block-end: 12px;
    grid-template-columns: 100px 3fr 1fr;
    grid-column-gap: 18px;

    @include mobile {
        margin-block-end: 14px;
        grid-template-columns: 84px 3fr 1fr;
        grid-column-gap: 14px;
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;
    }

    &:not(:last-of-type) {
        margin-block-end: 12px;
    }

    &-OutOfStock {
        text-align: end;
        font-size: 14px;
        font-weight: bold;

        @include mobile {
            white-space: nowrap;
        }
    }

    .Field {
        &_type_numberWithControls {
            margin-block-start: 0;
        }

        &-ErrorMessages {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    &-Image {
        aspect-ratio: 1;
        object-fit: contain;
        cursor: pointer;

        &Popup {
            .Popup-Content {
                max-width: var(--content-wrapper-width);
                width: 90%;
                max-height: 90%;
                height: 90%;

                img {
                    height: calc(100% - 2 * var(--popup-content-padding));
                    width: calc(100% - 2 * var(--popup-content-padding));
                    object-fit: contain;
                }
            }
        }
    }

    &-Title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        overflow: hidden;

        // .GroupedProductItem-TitleTopWrapper {
        //     display: flex;
        //     flex-direction: row;
        //     justify-content: space-between;
        // }

        p {
            margin-block-end: 12px;
            margin-block-start: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 90%;

            @include mobile {
                margin-block-end: 14px;
            }
        }

        &TopWrapper {
            align-items: center;
            
            @include mobile {
                width: 100%;
            }

            @include desktop {
                max-width: 100%;
            }
        }
    }

    &-Quantity .Field {
        margin: 0;
    }

    &-Quantity {
        input {
            @include mobile {
                min-width: 36px;
            }
        }
    }

    .ProductPrice {
        &-Price {
            font-size: 18px;
        }

        del {
            display: block;
        }
    }

    .TierPrices {
        grid-column: 2 / -1;
    }
}
