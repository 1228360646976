/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductAttributeValue {
    @include desktop {
        .ProductAttributeValue-Text {
            label {
                &:first-of-type {
                    .input-control {
                        margin-block-start: 2px;
                        border: 0;
                        background-color: var(--filter-bg-color);
                    }
                }
            }
        }
    }

    &-IconWrapper {
        position: relative;
        display: inline-flex;
    }

    &-IconGray {
        white-space: nowrap;
        inset-block-start: 2px;

        svg {
            fill: #E7E6E5;
        }
    }

    &-IconBrown {
        position: absolute;
        inset-inline-start: 0;
        white-space: nowrap;
        overflow: hidden;
        z-index: 1;
        inset-block-start: 2px;

        svg {
            fill: var(--primary-base-color)
        }
    }
}
