/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CategoryProductList {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
    }

    &-Page {
        &_layout {
            &_grid {
                display: grid;
                grid-auto-rows: max-content;
                grid-gap: 20px;

                @include narrow-desktop {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include wide-desktop {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include tablet {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include mobile {
                    grid-column-gap: 14px;
                    // remove margin from top to other pages
                    grid-template-columns: 1fr;
                    border: 0;
                }

                @include desktop {
                    grid-column: 2;
                    margin-block-start: 12px;
                }

                // remove margin from top to other pages

                ~ .CategoryProductList-Page {
                    @include desktop {
                        margin-block-start: 0;
                    }
                }
            }

            &_list {
                // display: block;
                // max-width: 600px;
                grid-template-columns: repeat(1, 1fr);

                .ProductCard-Link {
                    flex-direction: column;
                    max-width: 100%;
                    width: 100%;

                    .Image {
                        width: 100%;
                        padding-block-end: 20%;

                        .Image-Image {
                            // object-fit: none;
                            // height: auto;
                        }
                    }

                    .ProductCard-Figure {
                        &_isNotSampler {
                            .Image {    
                                .Image-Image {
                                    object-fit: unset;
                                    height: auto;
                                }
                            }
                        }

                        &_hasNoFormat, &_isCigarilloFormat {
                            img {
                                height: auto;
                                width: 200px;
                            }
                        }
                    }

                    .ProductCompareButton {
                        .Image {
                            width: var(--compare-button-size);
                        }
                    }

                    .ProductWishlistButton {
                        .Image,
                        &-Heart {
                            height: var(--wishlist-heart-size);
                            width: var(--wishlist-heart-size);
                        }
                    }
                }
            }
        }

        // remove margin from top to other pages

        ~ .CategoryProductList-Page {
            @include desktop {
                margin-block-start: 0;
            }
        }
    }

    &-ProductsMissing {
        padding: 12px;
        text-align: center;

        @include mobile {
            padding: 14px;
        }
    }

    &-More {
        min-height: 36px;

        @include mobile {
            min-height: 42px;
        }
    }

    &-LoadButton {
        background-color: var(--category-product-list-load-button-background);
        cursor: pointer;
        font-size: 17px;
        margin-block-end: 12px;
        padding: 1em 0;
        text-align: center;

        @include mobile {
            margin-block-end: 14px;
        }
    }
}
