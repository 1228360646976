/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductReviewItem {
    @include desktop {
        display: flex;
        grid-column-gap: 0px;
        justify-content: space-between;
    }

    margin: 0;
    word-break: break-word;
    padding-inline: 20px;
    padding-block: 20px;
    border: 0px solid var(--product-review-item-border);
    text-align: start;
    background-color: var(--color-white);

    &-RatingSummaryItem {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-block-end: 16px;

        @include mobile {
            margin-block-end: 7px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            --star-size: 20px;

            margin-block-start: 0;
            margin-inline-start: 0px;

            @include mobile {
                margin-block: 0 3px;
                margin-inline: 0px 0;
            }
        }
    }

    &-RatingItem {
        margin-inline-start: 10px;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0.04em;

        @include mobile {
            line-height: 20px;
        }
    }

    &-ReviewAuthor {
        margin-block: 0px 0;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.04em;
        text-transform: uppercase;


        @include mobile {
            line-height: 20px;
        }
    }
}
