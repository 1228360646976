/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */



.ProductActions {
    line-height: 16px;

    &-NameWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            flex-direction: column-reverse;
            align-items: baseline;
        }

        .ProductWishlistButton {
            width: unset;

            @include mobile {
                margin-block-end: 20px;
            }

            &-Button {
                flex-direction: row-reverse;

                @include mobile {
                    flex-direction: row;
                }
            }

            &-Text {
                display: block;
                margin-inline-end: 10px;
                font-size: 16px;
            }
        }
    }

    &-Attributes {
        padding: 0;
    }

    &-Brand {
        font-weight: bold;
        opacity: .48;
        font-size: 12px;
        line-height: 16px;
    }

    &-Title {
        margin-block: 8px;
        text-transform: none;

        @include mobile {
            font-weight: 300;
            font-size: 32px;
            line-height: 40px;
        }
    }

    &-ShortDescription {
        @include description-tags {
            line-height: 20px;
        }

        font-size: 14px;
        margin-block: 16px;
        line-height: 20px;

        div[itemprop="description"] {
            word-break: break-word;

            li::before {
                position: relative;
                margin-inline-end: 10px;
                inset-block-start: -1px;
            }
        }
    }

    &-Stock {
        font-weight: bold;
        order: 0;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            font-style: normal;
            font-size: 12px;
            line-height: 22px;
            margin-block-start: 8px;
        }
    }

    &-Sku {
        order: 1;

        @include mobile {
            font-weight: normal;
            font-size: 12px;
            margin-block-start: 4px;
            text-align: end;
        }

        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        order: 2;
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        @include mobile {
            inset-block-end: 0;
        }

        &Text {
            color: var(--primary-base-color);

            &:hover {
                color: var(--primary-dark-color);
            }

            &_isNotSafariOrIos {
                @include mobile {
                    vertical-align: -webkit-baseline-middle;
                }
            }
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                }

                .TextPlaceholder {
                    line-height: 30px;
                }
            }

            &_alerts {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }
    }

    &-Schema {
        min-width: 150px;
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        @include desktop {
            margin-block-start: 24px;
        }

        .TextPlaceholder {
            line-height: 30px;
        }
    }

    &-ActionButtons {
        display: flex;
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        &:nth-child(2) {
            @include mobile {
                align-items: start;
            }
        }

        &:nth-child(3) {
            @include mobile {
                margin-block-end: 22px;
            }
        }

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    &-AddToCartFixed {
        @include mobile-bottom-wrapper {
            padding: 12px 16px;
            inset-block-end: 16px;
            display: flex;
        }

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_numberWithControls {
                        @include mobile {
                            display: flex;
                        }
                    }
                }
            }
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        margin-block-start: 24px;

        .ProductActions-AddToCart {
            margin-inline-end: 16px;

            @include ultra-narrow-desktop {
                order: 10;
                margin-block-start: 24px;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline: 16px;
        }

        @include ultra-narrow-desktop {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            position: absolute;
        }
    }

    &-Qty {
        &.Field {
            margin-block: 0;

            @include desktop {
                margin-inline-end: 32px;
            }

            @include mobile {
                margin-inline-end: 12px;

                input {
                    min-width: 36px;
                }
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
            }

            &:active {
                border-color: var(--primary-dark-color);
            }

            @include hoverable {
                &:hover {
                    border-color: var(--primary-dark-color);
                }
            }
        }
    }

    &-AttributesWrapper {
        @include mobile {
            margin-block-end: 16px;
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;

        .GroupedProductsItem-TitleTopWrapper {
            @include desktop {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
        }
    }

    &-Reviews {
        margin-block-end: 10px;
        display: flex;

        @include mobile {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }

    .GroupedProductsItem {
        grid-template-columns: 5% 50% 1fr;
        align-items: center;
        grid-column-gap: 10px;
        flex: 1;

        @include mobile {
            grid-template-columns: 1fr 2fr 1fr;
        }

        &:not(:last-of-type) {
            margin-block-end: 50px;
        }

        &-Title {
            justify-content: space-between;
            flex-direction: column;

            .GroupedProductItem-TitleTopWrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            p {
                margin: 0;
                line-height: 24px;
            }

            .ProductPrice {
                min-height: unset;
            }

            .ProductPrice-PriceValue {
                margin: 0;
            }

            .GroupedProductItem-PricePerUnit {
                color: var(--price-color);
                font-weight: 600;
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
                margin-block: 0;
                vertical-align: middle;
            }
        }



        &-QuantityAddToCart {
            display: grid;
            grid-template-columns: 0fr 0fr 2fr;
            align-items: center;
            grid-column-gap: 14px;

            .AddToCart {
                margin: 0;
                border: 0;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                &-Label {
                    @include mobile {
                        display: none;
                    }
                }
            }

            .Field-Wrapper_type_select {
                height: 100%;
            }
        }

        &-Select {
            margin: 0px;
            height: 100%;

            .FieldSelect {
                height: 100%;

                .FieldSelect-Clickable {
                    height: 100%;
                }
            }

            .FieldSelect-Select {
                background-color: #F4F1EC;
                border: none;
                color: var(--secondary-dark-color);
                font-weight: 700;
                font-size: 12px;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
                border-radius: 0px;
                min-width: 70px;
                padding: 11px 15px;
                line-height: 20px;
                margin-block-start: 0px;
                height: 100%;
            }

            .ChevronIcon {
                fill: var(--secondary-dark-color);
            }
        }

        &-DeliveryIcon {
            display: flex;
            width: 30px;
            height: 100%;
            align-items: center;


            &_green {
                svg {
                    fill: #6A9632;
                }
            }

            &_yellow {
                svg {
                    fill: var(--primary-dark-color);
                }
            }

            &_red {
                svg {
                    fill: #971200;
                }
            }
        }
    }

    .ProductLinks {
        .ProductLinks-Title {
            font-size: 28px;
            line-height: 28px;
            padding-block-end: 10px;
        }

        .ProductLinks-Wrapper {
            padding-inline: 0px;

            .ProductLinks-List {
                @include desktop {
                    grid-template-columns: repeat(1, 1fr);
                    grid-gap: 0px;
                }

                @include mobile {
                    grid-template-columns: repeat(1, 1fr);
                }

                .ProductCard {
                    background-color: transparent;
                    border-bottom: 1px solid #E7E6E5;

                    .ProductCard-LinkInnerWrapper {
                        display: grid;
                        grid-template-columns: 1fr 5fr;
                        align-items: center;
                        grid-column-gap: 30px;
                        padding-block: 10px;

                        @include mobile {
                            grid-template-columns: 80px 1fr;
                        }

                        &:hover {
                            box-shadow: none;
                        }
    
                        .ProductCard-Header {
                            display: none;
                        }
    
                        .ProductCard-Link {
                            .ProductCard-FigureReview {
                                background-color: transparent;
                                padding: 0;

                                .ProductCard-Picture {
                                    padding-block-end: 50%;
                                    display: flex;
                                }
                            }
                        }
    
                        .ProductCard-Content {
                            padding: 0;
                            background-color: transparent;
    
                            .GroupedProductsItemList {
                                display: none;
                            }
    
                            .ProductCard-Name {
                                margin: 0;
                                padding: 0;
                                font-weight: 400;

                                @include mobile {
                                    font-size: 18px;
                                    white-space: break-spaces;
                                    line-height: 20px;
                                }
                            }
    
                            .ProductCard-Description {
                                display: block;
                                margin: 0;
                                margin-block-start: 10px;

                                @include mobile {
                                    font-size: 16px;
                                    white-space: break-spaces;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
